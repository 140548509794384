<!-- Button -->
<button mat-icon-button [matMenuTriggerFor]="userActions">
  <span class="relative">
    <img
      class="w-7 h-7 rounded-full"
      *ngIf="showAvatar && user && user.avatar"
      [src]="user.avatar" />
    <mat-icon
      *ngIf="!showAvatar || !user || !user.avatar"
      [svgIcon]="'heroicons_outline:user-circle'"></mat-icon>
  </span>
</button>

<mat-menu [xPosition]="'before'" #userActions="matMenu">
  <button mat-menu-item>
    <span class="flex flex-col leading-none">
      <span transloco="usercomponent.signInAs"></span>
      <span *ngIf="showAvatar && user" class="mt-1.5 text-md font-medium">{{
        user.email
      }}</span>
    </span>
  </button>
  <button mat-menu-item (click)="changePassword()">
    <span class="flex flex-col leading-none">
      <span transloco="usercomponent.resetPassword"></span>
    </span>
  </button>
  <mat-divider class="my-2"></mat-divider>

  <!--    <button mat-menu-item>-->
  <!--       <mat-icon [svgIcon]="'heroicons_outline:cog'"></mat-icon>-->
  <!--       <span>Settings</span>-->
  <!--    </button>-->
  <mat-divider class="my-2"></mat-divider>
  <button mat-menu-item (click)="signOut()">
    <mat-icon [svgIcon]="'heroicons_outline:logout'"></mat-icon>
    <span transloco="usercomponent.singout"></span>
  </button>
</mat-menu>

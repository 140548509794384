import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError, Observable, retry, throwError } from 'rxjs';
import { Intent } from '../types/intent.types';
import { Tenant } from '../types/tenant.types';
import { ResponseRequest } from '../types/responseRequest.types';
import { AuthService } from '../../core/auth/auth.service';
import {
  CountryLanguageConfiguration,
  GeneratedAnswer,
  GeneratedAnswerRequest,
  Language,
  LanguageConfiguration,
  LanguageConfigurationRequest,
  Translation,
  TranslationRequest,
  VoiceRecognitionConfiguration,
} from '../types/language.types';
import { Settings } from '../types/settings';
import { AppConfigService } from 'app/services/app.config.service';
import { ApplicationSettings } from '../types/appConfiguration';
import { DashboardResponse } from '../types/dashboard-response';
import {
  ConversationInformation,
  ConversationSMSRequest,
} from '../types/conversationInformation';
import { User } from '../types/user';
import { MinutesQuotaReponse } from '../types/minutes-quota-response';
import { Customer } from '../types/customer';

@Injectable({
  providedIn: 'root',
})
export class RestApiService {
  private appSettings: ApplicationSettings;

  constructor(
    private http: HttpClient,
    private appConfigService: AppConfigService
  ) {
    this.appSettings = this.appConfigService.getAppSettings();
  }

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
  };

  getChat(tenantId: string, contactId: string): Observable<any> {
    return this.http
      .get<ConversationInformation>(
        `${this.appSettings.adminBaseUrl}/${tenantId}/chat/contact/${contactId}`
      )
      .pipe(retry(1), catchError(this.handleError));
  }

  getChatList(tenantId: string): Observable<any> {
    return this.http
      .get<ConversationInformation>(
        `${this.appSettings.adminBaseUrl}/${tenantId}/chat/contact/list`
      )
      .pipe(retry(1), catchError(this.handleError));
  }

  getTenants(): Observable<Tenant[]> {
    return this.http
      .get<Tenant[]>(this.appSettings.adminBaseUrl + '/Tenant')
      .pipe(retry(1), catchError(this.handleError));
  }

  getResponse(tenant: string): Observable<Response[]> {
    return this.http
      .get<Response[]>(this.appSettings.adminBaseUrl + '/Response/' + tenant)
      .pipe(retry(1), catchError(this.handleError));
  }

  putResponse(
    tenant: string,
    id: number,
    response: ResponseRequest
  ): Observable<ResponseRequest> {
    return this.http
      .put<ResponseRequest>(
        this.appSettings.adminBaseUrl + '/Response/' + id + '/' + tenant,
        JSON.stringify(response),
        this.httpOptions
      )
      .pipe(retry(1), catchError(this.handleError));
  }

  getIntent(tenant: string): Observable<Intent[]> {
    return this.http
      .get<Intent[]>(this.appSettings.adminBaseUrl + '/Intent/' + tenant)
      .pipe(retry(1), catchError(this.handleError));
  }

  getLanguages(): Observable<Language[]> {
    return this.http
      .get<Language[]>(this.appSettings.adminBaseUrl + '/LanguageSettings')
      .pipe(retry(1), catchError(this.handleError));
  }
  getLanguageConfiguration(
    tenant: string
  ): Observable<LanguageConfiguration[]> {
    return this.http
      .get<LanguageConfiguration[]>(
        this.appSettings.adminBaseUrl + '/LanguageSettings/' + tenant
      )
      .pipe(retry(1), catchError(this.handleError));
  }

  getTranslation(
    from: string,
    to: string,
    text: string
  ): Observable<Translation> {
    const request: TranslationRequest = {
      text: text,
      to: to,
      from: from,
    };
    return this.http
      .post<Translation>(
        this.appSettings.adminBaseUrl + '/translate',
        JSON.stringify(request),
        this.httpOptions
      )
      .pipe(retry(1), catchError(this.handleError));
  }

  getSettings(tenant: string): Observable<Settings> {
    return this.http
      .get<Settings>(this.appSettings.adminBaseUrl + '/settings/' + tenant)
      .pipe(retry(1), catchError(this.handleError));
  }

  sendSms(
    tenant: string,
    conversationId: string,
    phoneNumber: string,
    smsText: string
  ): Observable<ConversationSMSRequest> {
    const request: ConversationSMSRequest = {
      conversationId: conversationId,
      phoneNumber: phoneNumber,
      smsText: smsText,
    };
    return this.http
      .post<ConversationSMSRequest>(
        `${this.appSettings.adminBaseUrl}/${tenant}/chat/contact/${conversationId}/sendSMS`,
        JSON.stringify(request),
        this.httpOptions
      )
      .pipe(retry(1), catchError(this.handleError));
  }

  getGeneratedAnswer(
    description: string,
    url: string,
    useDu: boolean
  ): Observable<GeneratedAnswer> {
    const request: GeneratedAnswerRequest = {
      url: url,
      question: description,
      useDu: useDu,
    };
    return (
      this.http
        .post<GeneratedAnswer>(
          'https://aicallai.orangefield-d57492e2.germanywestcentral.azurecontainerapps.io/ask',
          JSON.stringify(request),
          this.httpOptions
        )
        //.post<GeneratedAnswer>('http://127.0.0.1:5000/ask',JSON.stringify(request), this.httpOptions2)
        .pipe(retry(1), catchError(this.handleError))
    );
  }

  saveLanguageConfiguration(
    tenant: string,
    languageKey: string,
    voice: string,
    hotelLanguage: string,
    countryLanguageConfig: CountryLanguageConfiguration[],
    voiceRecognitionConfig: VoiceRecognitionConfiguration[],
    speechActivation: boolean,
    notificationEmail: string
  ): Observable<LanguageConfigurationRequest> {
    const request: LanguageConfigurationRequest = {
      defaultLanguageKey: languageKey,
      defaultVoice: voice,
      hotelLanguage: hotelLanguage,
      countryLanguageConfiguration: countryLanguageConfig,
      voiceRecognitionConfiguration: voiceRecognitionConfig,
      speechActivation: speechActivation,
      notificationEmail: notificationEmail,
    };
    return this.http
      .put<LanguageConfigurationRequest>(
        this.appSettings.adminBaseUrl + '/LanguageSettings/' + tenant,
        JSON.stringify(request),
        this.httpOptions
      )
      .pipe(retry(1), catchError(this.handleError));
  }

  trainNlp(tenant: string): Observable<string> {
    return this.http
      .get<string>(
        this.appSettings.adminBaseUrl + '/response/trainnlp/' + tenant
      )
      .pipe(retry(1), catchError(this.handleError));
  }

  translateEmpty(tenantId: string): Observable<string> {
    return this.http
      .get<string>(
        this.appSettings.adminBaseUrl + '/response/translateempty/' + tenantId
      )
      .pipe(retry(1), catchError(this.handleError));
  }

  getIsAdmin(tenantId: string): Observable<boolean> {
    return this.http
      .get<boolean>(
        this.appSettings.adminBaseUrl + `/${tenantId}/Users/IsAdmin`
      )
      .pipe(retry(1), catchError(this.handleError));
  }

  getUserRoles(tenantId: string): Observable<string[]> {
    return this.http
      .get<string[]>(
        this.appSettings.adminBaseUrl + `/${tenantId}/Users/GetRoles`
      )
      .pipe(retry(1), catchError(this.handleError));
  }
  getDashboard(
    tenantId: string,
    option: string
  ): Observable<DashboardResponse> {
    return this.http
      .get<DashboardResponse>(
        `${this.appSettings.adminBaseUrl}/dashboard/${tenantId}?filter=${option}`,
        this.httpOptions
      )
      .pipe(retry(1), catchError(this.handleError));
  }

  handleError(error: any) {
    let title = 'Error';
    let status = 0;
    let detail = '';

    if (error.error instanceof ErrorEvent) {
      // Get client-side error
      detail = error.error.message;
    } else {
      // Get server-side error
      title = error.error.title;
      detail = error.error.detail;
      status = error.status;
    }

    if (status == 500) {
      window.alert(detail);
    }
    return throwError(() => {
      return { title, detail, status };
    });
  }

  getUsers(tenantId: string): Observable<User[]> {
    return this.http
      .get<User[]>(this.appSettings.adminBaseUrl + `/${tenantId}/Users`)
      .pipe(retry(1), catchError(this.handleError));
  }
  deleteUser(tenantId: string, userId: string): Observable<void> {
    return this.http
      .delete<void>(
        this.appSettings.adminBaseUrl + `/${tenantId}/Users/${userId}`
      )
      .pipe(retry(1), catchError(this.handleError));
  }

  createUser(
    tenantId: string,
    name: string,
    email: string,
    admin: boolean,
    chat: boolean
  ) {
    const request: User = {
      name: name,
      admin: admin,
      chat: chat,
      email: email,
    };
    return this.http
      .post<User>(
        this.appSettings.adminBaseUrl + `/${tenantId}/Users`,
        JSON.stringify(request),
        this.httpOptions
      )
      .pipe(retry(1), catchError(this.handleError));
  }
  updateUser(
    tenantId: string,
    userId: string,
    name: string,
    email: string,
    admin: boolean,
    chat: boolean
  ) {
    const request: User = {
      name: name,
      admin: admin,
      chat: chat,
      email: email,
    };
    return this.http
      .put<User>(
        this.appSettings.adminBaseUrl + `/${tenantId}/Users/${userId}`,
        JSON.stringify(request),
        this.httpOptions
      )
      .pipe(retry(1), catchError(this.handleError));
  }

  updateCustomer(tenantId: string, name: string) {
    const request: Customer = {
      name: name,
    };
    return this.http
      .put<User>(
        this.appSettings.adminBaseUrl + `/tenant/${tenantId}`,
        JSON.stringify(request),
        this.httpOptions
      )
      .pipe(retry(1), catchError(this.handleError));
  }

  getQuota(tenantId: string): Observable<MinutesQuotaReponse> {
    return this.http
      .get<MinutesQuotaReponse>(
        `${this.appSettings.adminBaseUrl}/tenant/quota/${tenantId}`,
        this.httpOptions
      )
      .pipe(retry(1), catchError(this.handleError));
  }
}

<div class="flex flex-col max-w-240 md:min-w-160 max-h-screen -m-6">
  <!-- Header -->
  <div
    class="flex flex-0 items-center justify-between h-16 pr-3 sm:pr-5 pl-6 sm:pl-8 bg-primary text-on-primary">
    <div class="text-lg font-medium">
      {{ 'settingsPage.user.updateCustomerName' | transloco }}
    </div>
    <button mat-icon-button (click)="discard()" [tabIndex]="-1">
      <mat-icon
        class="text-current"
        [svgIcon]="'heroicons_outline:x'"></mat-icon>
    </button>
  </div>

  <form
    class="flex flex-col flex-auto p-6 sm:p-8 overflow-y-auto"
    [formGroup]="cnFormGroup"
    (ngSubmit)="onSubmit()">
    <!-- To -->
    <mat-form-field>
      <mat-label> {{ 'settingsPage.user.nameTitle' | transloco }}</mat-label>
      <input matInput formControlName="customerName" />
      <mat-error *ngIf="cnFormGroup.get('customerName').hasError('required')">
        {{ 'settingsPage.user.nameValidation' | transloco }}
      </mat-error>
    </mat-form-field>

    <div
      class="flex flex-col sm:flex-row sm:items-center justify-between mt-4 sm:mt-6">
      <div class="flex items-center mt-4 sm:mt-0">
        <button
          class="sm:mx-3"
          mat-stroked-button
          type="button"
          [disabled]="submitted"
          (click)="discard()"
          transloco="settingsPage.user.cancelButton"></button>
        <button
          class="order-first sm:order-last"
          mat-flat-button
          [color]="'primary'"
          [disabled]="submitted || cnFormGroup.invalid"
          type="submit"
          [transloco]="'settingsPage.user.updateButton'"></button>
      </div>
    </div>
  </form>
</div>

import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslocoService } from '@ngneat/transloco';
import { RestApiService } from 'app/modules/rest/rest-api.service';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'cn-form-modal',
  templateUrl: './cn-form-modal.component.html',
})
export class CnFormModalComponent implements OnInit, OnDestroy {
  cnFormGroup: FormGroup;
  submitted = false;
  tenantId: string;
  private _unsubscribeAll: Subject<any> = new Subject<any>();
  constructor(
    private formBuilder: FormBuilder,
    private translocoService: TranslocoService,
    private _restApiService: RestApiService,
    private _snackBar: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public matDialogRef: MatDialogRef<CnFormModalComponent>
  ) {}

  ngOnInit(): void {
    this.initialLoading();
  }

  private initialLoading(): void {
    this.cnFormGroup = this.formBuilder.group({
      customerName: ['', Validators.required],
    });

    //assign Customer Name
    if (this.data.customerName) {
      this.cnFormGroup.controls['customerName'].setValue(
        this.data.customerName
      );
    }
    this.tenantId = this.data.tenantId;
  }

  get formControl() {
    return this.cnFormGroup.controls;
  }

  ngOnDestroy(): void {}

  onSubmit() {
    this.submitted = true;
    if (this.cnFormGroup.valid) {
      this._restApiService
        .updateCustomer(this.tenantId, this.formControl.customerName.value)
        .pipe(takeUntil(this._unsubscribeAll))
        .subscribe({
          next: response => {
            this.submitted = false;
            window.location.reload();
            this.matDialogRef.close({
              responseMessage: response,
              customerUpdated: true,
            });
          },
          error: ({ status, detail }) => {
            if (status != 500) {
              this._snackBar.open(detail, '', { duration: 2000 });
            } else {
              this.submitted = false;
              this._snackBar.open(
                this.translocoService.translate(
                  'settingsPage.user.customerUpdateFailedMessage'
                ),
                '',
                { duration: 2000 }
              );
            }
          },
        });
    }
  }
  discard(): void {
    this.matDialogRef.close();
  }
}

import {
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject, takeUntil } from 'rxjs';
import { FuseMediaWatcherService } from '@fuse/services/media-watcher';
import {
  FuseNavigationItem,
  FuseNavigationService,
  FuseVerticalNavigationComponent,
} from '@fuse/components/navigation';
import { Navigation } from 'app/core/navigation/navigation.types';
import { NavigationService } from 'app/core/navigation/navigation.service';
import { TranslocoService } from '@ngneat/transloco';
import { UserRoleService, UserRoles } from 'app/role-service.service';
import { RestApiService } from 'app/modules/rest/rest-api.service';
import { TenantsService } from 'app/layout/common/tenants/tenants.service';
import { Tenant } from 'app/modules/types/tenant.types';
import { HsFormModalComponent } from './hs-form-modal/hs-form-modal.component';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { CnFormModalComponent } from './cn-form-modal/cn-form-modal.component';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'material-layout',
  templateUrl: './material.component.html',
  encapsulation: ViewEncapsulation.None,
})
export class MaterialLayoutComponent implements OnInit, OnDestroy {
  showChat: boolean;
  isScreenSmall: boolean;
  navigation: Navigation;
  private _unsubscribeAll: Subject<any> = new Subject<any>();
  isAdmin = false;
  tenant: Tenant;
  quotaAlertVisible = false;
  /**
   * Constructor
   */
  constructor(
    private _navigationService: NavigationService,
    private _fuseMediaWatcherService: FuseMediaWatcherService,
    private _fuseNavigationService: FuseNavigationService,
    private translocoService: TranslocoService,
    private restApiService: RestApiService,
    private _changeDetectorRef: ChangeDetectorRef,
    private _tenantService: TenantsService,
    private _router: Router,
    public dialog: MatDialog,
    private _snackBar: MatSnackBar
  ) {}

  // -----------------------------------------------------------------------------------------------------
  // @ Accessors
  // -----------------------------------------------------------------------------------------------------

  /**
   * Getter for current year
   */
  get currentYear(): number {
    return new Date().getFullYear();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit(): void {
    this.initialLoading();
  }

  private initialLoading(): void {
    this._tenantService.getTenantObservable().subscribe(tenant => {
      this.tenant = tenant;
      this.restApiService
        .getUserRoles(tenant.name)
        .pipe(takeUntil(this._unsubscribeAll))
        .subscribe(roles => {
          this.showChat = roles.find(role => role === 'CHAT') ? true : false;
          this.isAdmin = roles.includes('CUSTOMER_ADMIN');
          // Subscribe to navigation data
          this.navigationData();
        });
    });

    // Subscribe to media changes
    this._fuseMediaWatcherService.onMediaChange$
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(({ matchingAliases }) => {
        // Check if the screen is small
        this.isScreenSmall = !matchingAliases.includes('md');
      });
  }

  /**
   * On destroy
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next(null);
    this._unsubscribeAll.complete();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Toggle navigation
   *
   * @param name
   */
  toggleNavigation(name: string): void {
    // Get the navigation
    const navigation =
      this._fuseNavigationService.getComponent<FuseVerticalNavigationComponent>(
        name
      );

    if (navigation) {
      // Toggle the opened status
      navigation.toggle();
    }
  }

  navigationData() {
    this._navigationService.navigation$
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((navigation: Navigation) => {
        this.navigation = navigation;

        this.navigation.horizontal = this.showChat
          ? this.navigation.horizontal
          : this.navigation.horizontal.filter(nav => nav.title !== 'Chat');
        this.navigation.default = this.showChat
          ? this.navigation.default
          : this.navigation.default.filter(nav => nav.title !== 'Chat');
        this._changeDetectorRef.markForCheck();
      });
  }

  onClick() {
    this._navigationService.get().subscribe(res => {
      this.navigationData();
    });
  }

  horizontalnavigations() {
    if (this.navigation) {
      return this.navigation.horizontal.map((item, index) => {
        return {
          ...item,
          title: this.translocoService.translate(
            this.navigation.default[index].title
          ),
        };
      });
    }
  }

  get tooltipContent() {
    return this.translocoService.translate('setting');
  }
  get changeNameTooltipContent() {
    return this.translocoService.translate(
      'settingsPage.user.updateCustomerName'
    );
  }

  routeToSettingPanel(): void {
    this._router.navigateByUrl(`/${this.tenant.name}/setting`);
  }

  onClickQuestion(): void {
    this.dialog.open(HsFormModalComponent);
  }

  changeNamePopUp(): void {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      tenantId: this.tenant.name,
      customerName: this.tenant.description,
    };
    const dialogRef = this.dialog.open(CnFormModalComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this._snackBar.open(
          this.translocoService.translate(
            'settingsPage.user.customerUpdatedMessage'
          ),
          '',
          { duration: 2000 }
        );
        this.initialLoading();
      }
    });
  }
}
